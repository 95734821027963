import React, { useEffect } from "react";
import "./paymentfail.css";
import { useNavigate } from 'react-router-dom';
// import { createBrowserHistory } from 'history';

const PaymentFail = () => {
    const navigate = useNavigate()

    return (
        <div className="payment-success-container">
            <div className="payment-success-inner">
                <p className='payment-success-title'>결제 실패</p>
             
                <p className='payment-success-contact'>기타 문의 사항은 고객센터를 통해서 연락부탁드립니다.</p>
                <p className='payment-success-contact'>*031-287-2167</p>
                <button onClick={()=>navigate('/store')} className='payment-success-btn'>홈으로 이동하기</button>
            </div>
        </div>
    );
};

export default PaymentFail;
