import axios, { toFormData } from "axios";
import { REACT_APP_HOST } from "./config";

const authHeader = () => {
    const obj = JSON.parse(localStorage.getItem("authSellerUser"));

    if (obj && obj.accessToken) {
        return { Authorization: obj.accessToken };
    } else {
        return {};
    }
};

const getRequest = async (url, config) => {
    return axios
        .get(`${REACT_APP_HOST}${url}`, {
            ...config,
            headers: authHeader(),
        })
        .catch((e) => {
            if (e.response.status === 401 && window.location.pathname !== "/logout" && window.location.pathname !== "/login") {
                window.location.pathname = "/logout";
            }
        });
};

const sendLoginRequest = async (url, param) => {
    try {
        const { data } = await axios.post(`${REACT_APP_HOST}${url}`, param);

        return data;
    } catch (error) {
        return error?.response?.data;
    }
};

const postRequest = (url, data, config = {}) => {
    return axios
        .post(`${REACT_APP_HOST}${url}`, data, {
            ...config,
            headers: authHeader(),
        })
        .then((res) => {
            if (res?.data?.result === "Y") return res;
        });
};

const putRequest = (url, data, config = {}) => {
    return axios.put(`${REACT_APP_HOST}${url}`, data, {
        ...config,
        headers: authHeader(),
    });
};

const deleteRequest = (url, config) => {
    return axios.delete(`${REACT_APP_HOST}${url}`, {
        ...config,
        headers: authHeader(),
    });
};

//4000
export const getProductsList = async (params) => {
    try {
        const { data } = await getRequest(`/das/products`, { params });
        return data;
    } catch (e) {
        return { status: "FAILED" };
    }
};

//4001
export const updatePurchaseGoods = async (body) => {
    try {
        const { data } = await postRequest(`/das/product/purchase`, body);
        return data;
    } catch (e) {
        console.log(e);
        return { status: "FAILED" };
    }
};
