import { useEffect, useRef, useState } from "react";
import "./store.css";
import { useRecoilState } from "recoil";
import FirstPage from '../../components/Store/FirstPage/FirstPage';

function Store() {


    return (
        <>
            <FirstPage />
        </>
    );
}

export default Store;
