// import { IcLastArrowDown } from "../../constants/icons";
import { useState } from "react";
import { IcInsta, IcTwitter, IcYoutube } from "../../constants/icons";
import useLanguage from "../../hooks/useLanguage";
import "./footer.css";
import { INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL } from "../../constants/link";
import footerImg from "../../assets/images/footerIcon.png";
import { Link, useLocation } from "react-router-dom";
import Privacy_policy from "../../assets/term/개인정보처리방침.pdf";
import Terms_of_use from "../../assets/term/이용약관.pdf";

const POLICY_LIST = {
    service: "footer.service",
    privacy: "footer.privacy",
    paid: "footer.paid-service",
    youth: "footer.youth-protect",
};

const FooterMobile = () => {
    const [curPolicy, setCurPolicy] = useState("service");
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useLanguage();
    const location = useLocation();

    // const isStore = location.pathname.startsWith("/store");
    const isStore = true;

    return (
        <div className="footer-mobile">
            {!isStore ? (
                <div className="footer-mobile-bottom">
                    <img src={footerImg} alt="footer-img" width="80px" />
                    <div className="footer-mobile-bottom-info">
                        <p>1 Paya Lebar Link #06-08, PLQ2,</p>
                        <p>Paya Lebar Quarter, Singapore (408533)</p>
                        <span>contact@dasvers.com {"  |"}</span>
                        <span>(+65) 6381 6390</span>
                        <Link className="footer-link" to="https://www.dasuniverse.io">
                            www.dasuniverse.io
                        </Link>
                    </div>
                    <span>© DAS UNIVERSE. All Rights Reserved.</span>
                </div>
            ) : (
                <div className="footer-mobile-bottom">
                    <img
                        src={
                            "https://static.wixstatic.com/media/420a0e_3f70c5b1e2e64253aba16806586b0538~mv2.png/v1/fill/w_98,h_92,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/%EB%8B%A4%EC%8A%A4-%EB%A1%9C%EA%B3%A0_%EB%8B%A4%EC%8A%A4%EB%B2%84%EC%8A%A4_%ED%9D%B0.png"
                        }
                        alt="footer-img"
                        width="80px"
                    />
                        <p>주식회사 다스버스</p>
                    <div className="footer-mobile-bottom-info">
                        <p>대표이사 박길홍</p>
                        <p>주소 서울특별시 서초구 서초대로60길 9-4 WH빌딩</p>
                        <span>사업자등록번호 341-81-02096 {"  |  "}</span>
                        <span>(+82) 02-6495-2167</span>
                        {/* <Link className="footer-link" to="https://www.dasuniverse.io">
                            www.dasuniverse.io
                        </Link> */}
                    </div>

                    <div className="footer-mobile-bottom-info ">
                        <a className="line" href={Terms_of_use} target="_blank">
                            이용약관
                        </a>
                        <span>{"  |"}</span>
                        <a className="line" href={Privacy_policy} target="_blank">
                            개인정보처리방침
                        </a>
                    </div>
                    <span>© 2024. DASVERSE. all rights reserved.</span>
                </div>
            )}
        </div>
    );
};

export default FooterMobile;
