import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function FormInput({
    name,
    control,
    invalid = false,
    disabled = false,
    placeholder = "",
    type = "text",
    groupText = "",
    maxLength,
    className = "",
    defaultValue = "",
}) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => {
             return   <input className={className} maxLength={maxLength} type={type} {...field} disabled={disabled} placeholder={placeholder} />;
            }}
        />
    );
}
