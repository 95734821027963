import { useEffect, useRef, useState } from "react";
import "./storeorder.css";
import { useRecoilState } from "recoil";
import Payment from '../../components/Store/Payment/Payment';
import PaymentSuccess from '../../components/Store/PaymentSuccess/PaymentSuccess';

function StoreOrder() {
    const [openSuccess, setOpenSuccess] = useState(false)


    return (
        <>
            <Payment />
        </>
    );
}

export default StoreOrder;
