import React, { useEffect, useState } from "react";
import "./paymentsuccess.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { currency } from '../../../utils/join';

const PaymentSuccess = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location?.search)
    const order_id = queryParams.get('order_id')
    const payment_price = queryParams.get('payment_price')

    const [number, setNumber] = useState('0000000')




    
    return (
        <div className="payment-success-container">
            <div className="payment-success-inner">
                <p className='payment-success-title'>결제가 완료되었습니다!</p>
                <p className='payment-success-content'>
                    고객님이 주문하신 주문번호는 <span className='block'><span className='bold'>{order_id}</span>입니다.</span>
                </p>

                <div className="payment-success-price">
                    <p>총 결제 금액</p>
                    <p className='bold'>{currency(payment_price)}원</p>
                </div>
                <p className='payment-success-contact'>기타 문의 사항은 <span className='block'>고객센터를 통해서 연락부탁드립니다.</span></p>
                <p className='payment-success-contact'>*031-287-2167</p>
                <button onClick={()=>navigate('/store')} className='payment-success-btn'>홈으로 이동하기</button>
            </div>
        </div>
    );
};

export default PaymentSuccess;
