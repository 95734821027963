import { JOIN_URL } from "../constants/link";

export const join = (lang) => {
    window.open(JOIN_URL + lang);
};



// 숫자 3자리 ,
export const currency = (number) => {
    if(number){
      return number.toLocaleString()
    }else {
      return 0
    }
  };