import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./Pages/Layout/Layout";
import OTT from "./Pages/OTT/OTT";
import Stick from "./Pages/Stick/Stick";
import ScrollToTop from "./context/ScrollToTop";
import StickGuideLayout from "./Pages/StickGuide/StickGuideLayout";
import DailyArtStoryInvite from "./Pages/Event/DailyArtStoryInvite";
import Display from "./Pages/Display/Display";
import Store from './Pages/Store/Store';
import StoreOrder from './Pages/StoreOrder/StoreOrder';
import PaymentSuccess from './components/Store/PaymentSuccess/PaymentSuccess';
import PaymentFail from './components/Store/PaymentFail/PaymentFail';

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<OTT />} />
                    <Route path="stick" element={<Stick />} />
                    <Route path="/store" element={<Store />} />
                    <Route path="/store/:id" element={<StoreOrder />} />
                    <Route path="/store/success" element={<PaymentSuccess />} />
                    <Route path="/store/fail" element={<PaymentFail />} />
                    <Route path="display" element={<Display />} />
                </Route>
                <Route path="/stick-guide" element={<StickGuideLayout />} />
                <Route path="/dasverse" element={<DailyArtStoryInvite />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
