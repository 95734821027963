import { useEffect, useState } from "react";
import {
    IcHeaderBar,
    IcHeaderBarDark,
    IcHeaderLogo,
    IcHeaderLogoDark,
    IcHeaderMobileLogo,
    IcHeaderMobileLogoDark,
    IcLangArrow,
    IcLangArrowDark,
} from "../../constants/icons";
import "./header.css";
import useLanguage from "../../hooks/useLanguage";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { LANG } from "../../constants/utils";
import { useRecoilState } from "recoil";
import { loginAtomState, positionAtomState } from "../../stores/atom";
import { join } from "../../utils/join";

const Header = (props) => {
    const { openNavModal } = props;
    const { t, langState, changeLanguage } = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();

    const [showLang, setShowLang] = useState(false);
    const [curLang, setCurLang] = useState("");
    const [isDark, setIsDark] = useState(false);
    const isLight = location.pathname.startsWith('/store')
    console.log('isLight',isLight)

    const [position, setPositionState] = useRecoilState(positionAtomState);
    const [loginState, setLoginState] = useRecoilState(loginAtomState);

    const getScroll = () => {
        if (!position.isStick && !position.isDisplay.flag) {
            if (window.scrollY > position.END) {
                setIsDark(false);
            } else if (window.scrollY > position.START) {
                setIsDark(true);
            } else {
                setIsDark(false);
            }
        } else if (position.isDisplay.flag) {
            if (window.scrollY > position.isDisplay.secondStart) {
                setIsDark(true);
            } else if (window.scrollY > position.isDisplay.firstEnd) {
                setIsDark(false);
            } else if (window.scrollY > position.isDisplay.firstStart) {
                setIsDark(true);
            } else {
                setIsDark(false);
            }
        } else {
            setIsDark(false);
        }
    };

    useEffect(() => {
        const _curLang = LANG.filter((li) => li.code === langState);
        setCurLang(_curLang[0]?.title);
    }, [langState]);

    useEffect(() => {
        window.addEventListener("scroll", getScroll);
        return () => {
            window.removeEventListener("scroll", getScroll);
        };
    }, [position]);

    const onClickLogo = () => {
        window.scrollTo(0, 0);
        navigate("/");
    };

    return (
        <div className={`header ${isDark ? "dark" : ""} ${isLight? "light" : ""}`}>
            <div onClick={onClickLogo}>
                {!isDark ? (
                    <>
                        <IcHeaderLogo className="header-logo" />
                        <IcHeaderMobileLogo className="header-logo-mobile" />
                    </>
                ) : (
                    <>
                        <IcHeaderLogoDark className="header-logo" />
                        <IcHeaderMobileLogoDark className="header-logo-mobile" />
                    </>
                )}
            </div>
            <div className="header-nav">
                <NavLink to="/" className={({ isActive }) => (isActive ? "active" : "")}>
                    Daily Art Story
                </NavLink>
                {/* <Link to="https://www.dasuniverse.io" className={({ isActive }) => (isActive ? "active" : "")}>
                    {t("header.stick")}
                </Link> */}
                <NavLink to="/store" className={({ isActive }) => (isActive ? "active" : "")}>
                    29MALL 쇼핑
                </NavLink>
                <NavLink to="display" className="item-hidden">
                    {t("header.display")}
                </NavLink>
                {/* 기존 */}
                {/* <NavLink to="display" className={({ isActive }) => (isActive ? "active" : "")}>
                    {t("header.display")}
                </NavLink> */}

                {/* <a
                    onClick={() => {
                        window.open("https://demo.dailyartstory.com");
                    }}
                >
                    {t("header.demo")}
                </a> */}
            </div>
            <div className="header-right">
                <div
                    className={`header-lang ${showLang ? "active" : ""}`}
                    onClick={() => {
                        setShowLang((prev) => !prev);
                    }}
                >
                    <p>{curLang}</p>
                    {!isDark ? <IcLangArrow /> : <IcLangArrowDark />}
                    {showLang && (
                        <div className="header-lang-select">
                            {LANG.map((li) => (
                                <p
                                    key={li.code}
                                    onClick={() => {
                                        changeLanguage(li.code);
                                    }}
                                    className={langState === li.code ? "active" : ""}
                                >
                                    {li.title}
                                </p>
                            ))}
                        </div>
                    )}
                </div>

                {loginState && (
                    <button
                        // className="header-btn"
                        className="item-hidden"
                        onClick={() => {
                            join(langState);
                        }}
                    >
                        {t("header.join")}
                    </button>
                )}
                {!isDark ? (
                    <IcHeaderBar className="header-right-bar" onClick={openNavModal} />
                ) : (
                    <IcHeaderBarDark className="header-right-bar" onClick={openNavModal} />
                )}
            </div>
        </div>
    );
};

export default Header;
