import React, { useEffect, useState } from "react";
import bgSrc from "../../../assets/images/store/store_banner_bg.png";
import bgMobileSrc from "../../../assets/images/store/store_banner_bg_mobile.png";
import TitleSrc from "../../../assets/images/store/mall_first_title.png";
import TitlSubeSrc from "../../../assets/images/store/mall_first_title_sub.png";
import TicketSubTitle from "../../../assets/images/store/mall_item_ticket_sub.png";
import TicketTitle from "../../../assets/images/store/mall_item_ticket_title.png";
import "./firstpage.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { getProductsList } from '../../../utils/apiList';
import { currency } from '../../../utils/join';

const FirstPage = () => {
    const navigation = useNavigate()
    const [itemList, setItemList] = useState([])
    const [newItem, setNewItem] = useState(null)
    

    const getItemList = async () => {
        try {

            const body = {
                timestamp : Math.floor(Date.now() / 1000)
            }
            const res = await getProductsList(body);
            if( res?.result === 'Y') {
                setItemList(res?.best_item_list || [])
                setNewItem(res?.new_item_list[0] || null)
            }
        } catch (err) {}
    }

    useEffect(()=> {
        getItemList()
    },[])
    return (
        <div className='first-container'>
            <div className="first-page">
                <div className="first-page-body">
                    <div className="display-first-top ">
                        <div className="first-flex-center">
                            <img src={TitleSrc} className="first-page-title-img" />
                            <img src={TitlSubeSrc} className="first-page-title-img" />
                        </div>
                        <p className={`display-title mall-first-subtext mall-text`}>
                            {
                                "데일리아트스토리는 오래된 섬유 공장을 재생하여 도심 속 문화 공간으로 변화시킨 곳이며 데일리, 아트 스토리 스튜디오로 이루어진 아트컴플렉스입니다. 건물 외관부터 내부 전시까지 모든 부분에서 색채를 통한 상호작용을 중요시 하며, 시간과 색을 주제로 현실과 가상 세계가 만나는 예술 콘텐츠를 제공합니다."
                            }
                        </p>
                    </div>
                </div>
                <img src={bgSrc} className="first-page-bg" />
                <img src={bgMobileSrc} className="first-page-bg-mobile" />
            </div>
            <div className="item-list">
                <div className="new-item-wrap">
                    <p className="title">New Item</p>
                    <div className="new-item-card">
                        <div className="new-item-card-img">
                            <img src={TicketSubTitle} className="first-page-ticket-sub-img" />
                            <img src={TicketTitle} className="first-page-ticket-img" />
                        </div>
                        <div className="new-item-card-content">
                            <p className="title">데일리아트스토리 패밀리 이용권 출시 </p>
                            <p className="content">
                                <span>[F&B 소개]</span>
                                {
                                    "\n대형 디지털 아트작품이 전시된 갤러리 분위기의 공간에서 디저트와 파스타류, 스페셜티, 커피 외 다양한 음료를 즐기세요!\n\n"
                                }
                                <span>[전시 소개]</span>
                                {
                                    "\n우리의 모습은 정형화되어 있지만 그 내면은 다양한 Persona를 가지고 있습니다. Mind Your Colour에서 자신 내면의 색을 찾아가는 여정을 시작해 보세요!"
                                }
                            </p>
                            <button className="btn" onClick={()=>navigation(`/store/${newItem?.id}`, {state : {data : newItem}})}>{"구매하기 >"}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="best-list">
                <div className="new-item-wrap">
                    <p className="title">Best Item</p>
                    <div className="best-card-wrap">
                        {itemList.length !== 0 &&
                            itemList.map(el => 
                                <NavLink to={`/store/${el?.id}`} state={{data : el}} className="best-card">
                                <div className="best-card-img" style={{ backgroundImage: `url(${el?.image})` }}></div>
                                <p className="best-card-title">{el?.title}</p>
                                <p className="best-card-content">{el?.description}</p>
                                <p className="best-card-price">{currency(el?.price)}원</p>
                            </NavLink>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstPage;
